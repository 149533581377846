import React from "react";

const privacypolicy = () => {
    return (
      <>
        <div className="spcae-y-5 container mx-auto my-10">
          <h1 className="mb-5 text-center">Privacy policy</h1>
          <div className="mb-5">
            <h3>Effective on January 1, 2024</h3>
            <p>
              This privacy policy describes how Movermate collects and uses the
              personal information you provide on our Web site:
              www.movermate.com.au and through our service app.movermate.com.au It
              also describes the choices available to you regarding our use of
              your personal information and how you can access and update this
              information.
            </p>
          </div>
          <div className="mb-5">
            <h3>Collection and use of personal information</h3>
            <p>We collect the following personal information from you</p>
            <ul>
              <li>
                Contact Information such as name, email address, mailing address,
                phone number
              </li>
              <li>
                Unique Identifiers such as user name, account number, password
              </li>
              <li>
                Preferences Information such as product wish lists, order history,
                marketing preferences
              </li>
            </ul>
            <p>We also collect the following information from you</p>
            <ul>
              <li>
                Information about your business such as company name, company
                size, business type
              </li>
              <li>
                As is true of most Web sites, we automatically gather information
                about your computer such as your IP address, browser type,
                referring/exit pages, and operating system.
              </li>
            </ul>
          </div>
          <div className="mb-5">
            <h3>We use this information to</h3>
            <ul>
              <li>Fulfill your order</li>
              <li>Send you an order confirmation</li>
              <li>
                Assess the needs of your business to determine suitable products
              </li>
              <li>Send you requested product or service information</li>
              <li>Send product updates or warranty information</li>
              <li>Respond to customer service requests</li>
              <li>Administer your account</li>
              <li>Send you a newsletter</li>
              <li>Send you marketing communications</li>
              <li>Display content based upon your interests</li>
            </ul>
          </div>
          <div className="mb-5">
            <h3>Choice/opt-out</h3>
            <p>
              You may choose to stop receiving our newsletter or marketing emails
              by following the unsubscribe instructions included in these emails
              or you can contact us at{" "}
              <a
                href="mailto:support@movermate.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@movermate.com.au{" "}
              </a>
              .
            </p>
          </div>
          <div className="mb-5">
            <h3>Information sharing</h3>
            <p>
              We will share your personal information with third parties only in
              the ways that are described in this privacy policy. We do not sell
              your personal information to third parties.
            </p>
            <p>
              We may provide your personal information to companies that provide
              services to help us with our business activities such as shipping
              your Movermate subscription order or offering customer service.
              These companies are authorized to use your personal information only
              as necessary to provide these services to us.
            </p>
            <p>We may also disclose your personal information</p>
            <ul>
              <li>
                as required by law such as to comply with a subpoena, or similar
                legal process
              </li>
              <li>
                when we believe in good faith that disclosure is necessary to
                protect our rights, protect your safety or the safety of others,
                investigate fraud, or respond to a government request,
              </li>
              <li>
                if Movermate is involved in a merger, acquisition, or sale of all
                or a portion of its assets, you will be notified via email and/or
                a prominent notice on our Web site of any change in ownership or
                uses of your personal information, as well as any choices you may
                have regarding your personal information,
              </li>
              <li>to any other third party with your prior consent to do so.</li>
            </ul>
            <p>Co- branded relationships</p>
            <p>
              At times we may enter into co-branded relationships. When we enter
              into these types of relationships we will share your name and email
              address in order for our co-branded partner to provide related
              services to you. Our privacy policy does apply to the information
              collected on these co-branded sites.
            </p>
            <p>Third party e-commerce solutions provider</p>
            <p>
              Our shopping cart is hosted by our e-commerce solutions provider.
              They host our ordering system, and collect your billing information
              directly from you for the purpose of processing your order. This
              company does not use this information for any other purpose.
            </p>
          </div>
          <div className="mb-5">
            <h3>Cookies and other tracking technologies</h3>
            <p>
              A cookie is a small text file that is stored on a user’s computer
              for record-keeping purposes. We use cookies on this site. We do not
              link the information we store in cookies to any personal information
              you submit while on our site.
            </p>
            <p>
              We use both session ID cookies and persistent cookies. A user’s
              session cookie for a website exists in temporary memory only while
              the user is reading and navigating the website. When an expiry date
              or validity interval is not set at cookie creation time, a session
              cookie is created. Web browsers normally delete session cookies when
              the user closes the browser. A persistent cookie remains on your
              hard drive for an extended period of time. A persistent cookie will
              outlast user sessions. If a persistent cookie has its Max-Age set to
              1 year, then, within the year, the initial value set in that cookie
              would be sent back to the server every time the user visited the
              server. This could be used to record a vital piece of information
              such as how the user initially came to this website. For this reason
              persistent cookies are also called tracking cookies. You can remove
              persistent cookies by following directions provided in your Internet
              browser’s ‘ help ‘&nbsp; directory.If you reject cookies, you may
              still use our site, but your ability to use some areas of our site,
              such as contests or surveys, will be limited.
            </p>
            <p>
              The use of cookies by our partners, affiliates, tracking utility
              company, service providers is not covered by our privacy policy. We
              do not have access or control over these cookies. Our partners,
              affiliates, tracking utility company, service providers use session
              ID cookies to make it easier for you to navigate our site, in order
              for you to use the shopping cart, etc.
            </p>
            <p>
              We use a third party to gather information about how you and others
              use our Web site. For example, we will know how many users access a
              specific page and which links they clicked on. We use this
              aggregated information to understand and optimize how our site is
              used.
            </p>
          </div>
          <div className="mb-5">
            <h3>Links to other web sites</h3>
            <p>
              Our site includes links to other Web sites whose privacy practices
              may differ from those of Movermate. If you submit personal
              information to any of those sites, your information is governed by
              their privacy policies. We encourage you to carefully read the
              privacy policy of any Web site you visit.
            </p>
          </div>
          <div className="mb-5">
            <h3>Social media widgets</h3>
            <p>
              Our Web site includes Social Media Widgets, such as the Share this
              button or interactive mini-programs that run on our site. These
              Features may collect your IP address, which page you are visiting on
              our site, and may set a cookie to enable the Feature to function
              properly. Social Media Features and Widgets are either hosted by a
              third party or hosted directly on our Site. Your interactions with
              these Features are governed by the privacy policy of the Twitter,
              Facebook, Gmail, Google Reader, Linkedin, Myspace, Delicious, digg,
              reddit, StumbleUpon, Google Bookmarks, ycombinator, Bing, blinklist,
              Sphinn, posterous, tumblr.
            </p>
          </div>
          <div className="mb-5">
            <h3>Security</h3>
            <p>
              The security of your personal information and our clients
              information is important to us. When you enter sensitive information
              (such as login credentials) we encrypt the transmission of that
              information using secure socket layer technology (SSL).
            </p>
            <p>
              When we collect personal information directly from you, we follow
              generally accepted industry standards to protect the personal
              information submitted to us, both during transmission and once we
              receive it. No method of transmission over the Internet, or method
              of electronic storage, is 100% secure, however. Therefore we cannot
              guarantee its absolute security. If you have any questions about
              security on our Web site, you can contact us at{" "}
              <a
                href="mailto:support@movermate.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@movermate.com.au
              </a>
            </p>
            <p>
              In order to make a purchase from us, you must use our shopping cart
              provider to finalize and pay for your order. Its privacy policy and
              security practices will also apply to your information. We encourage
              you to read that privacy policy before providing your information.
            </p>
          </div>
          <div className="mb-5">
            <h3>Location Data</h3>
            <p>
              The Mover BOL app collects location data to enable location tracking
              and ETA calculation even when the app is closed or not in use.
            </p>
          </div>
          <div className="mb-5">
            <h3>Additional policy information</h3>
            <h3>Correcting and Updating Your Personal Information</h3>
            <p>
              To review, delete or update your personal information to ensure it
              is accurate, contact us at{" "}
              <a
                href="mailto:support@movermate.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@movermate.com.au
              </a>
              . We will respond to your request to access within 30 days.
            </p>
            <p>
              We will retain your information for as long as your account is
              active or as needed to provide you services. If you wish to cancel
              your account or request that we no longer use your information to
              provide you services contact us at{" "}
              <a
                href="mailto:support@movermate.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@movermate.com.au
              </a>
              . We will retain and use your information as necessary to comply
              with our legal obligations, resolve disputes, and enforce our
              agreements.
            </p>
          </div>
          <div className="mb-5">
            <h3>
              Information related to data collected through the Movermate CRM
              website
            </h3>
            <p>
              <b>Movermate</b> collects information under the direction of its
              Clients, and has no direct relationship with the individuals whose
              personal data it processes.
            </p>
          </div>
          <div className="mb-5">
            <h3>Access to data controlled by our clients</h3>
            <p>
              Movermate has no direct relationship with the individuals whose
              personal data it processes. An individual who seeks access, or who
              seeks to correct, amend, or delete inaccurate data should direct his
              query to the Movermate’s Client (the data controller). If the Client
              requests Movermate to remove the data, we will respond to their
              request within 30 business days.
            </p>
          </div>
          <div className="mb-5">
            <h3>Choice</h3>
            <p>
              We collect information for our clients, if you are a customer of one
              of our Clients and would no longer like to be contacted by one of
              our Clients that use our service, please contact the client that you
              interact with directly.
            </p>
          </div>
          <div className="mb-5">
            <h3>Service provider, sub-processors/onward transfer</h3>
            <p>
              Movermate may transfer personal information to companies that help
              us provide our service. Transfers to subsequent third parties are
              covered by the provisions in this Policy regarding notice and choice
              and the service agreements with our Clients.
            </p>
          </div>
          <div className="mb-5">
            <h3>Data retention</h3>
            <p>
              Movermate will retain personal data we process on behalf of our
              Clients for as long as needed to provide services to our Client.
              Movermate will retain and use this personal information as necessary
              to comply with our legal obligations, resolve disputes, and enforce
              our agreements.
            </p>
          </div>
          <div className="mb-5">
            <h3>Notification of privacy policy changes</h3>
            <p>
              We may update this privacy policy to reflect changes to our
              information practices. If we make any material changes we will
              notify you by email (sent to the e-mail address specified in your
              account) or by means of a notice on this Site prior to the change
              becoming effective. We encourage you to periodically review this
              page for the latest information on our privacy practices.
            </p>
          </div>
          <div className="mb-5">
            <h3>Gmail Add-on</h3>
            <p>
              When Movermate Add-on for Gmail is running within an opened email,
              <br />
              1 .It will have access to view your email messages and email message
              metadata.
              <br />
              2. It can create a network connection to read and write data from an
              external service, which, in this case, is the Movermate Platform.
            </p>
            <p>
              Movermate’s use and transfer of information received from Google
              APIs to any other app will adhere to{" "}
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#ff9800" }}
              >
                Google API Services User Data Policy
              </a>
              , including the Limited Use requirements.
            </p>
          </div>
          <p>{/* Google Calendar Privacy Policy*/}</p>
          <div className="mb-5">
            <h3>Contact information</h3>
            <p>
              You can contact us about this privacy Policy by writing or email us
              at the address below:
            </p>
            <h3>Movermate</h3>
            <p>3/100 Campbell St,</p>
            <p>Bowen Hills QLD 4006, Australia</p>
            <p>
              Email:{" "}
              <a
                href="mailto:support@movermate.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@movermate.com.au
              </a>
            </p>
            <p>
              Phone: <a href="tel:+61 460 010 101">+61 460 010 101</a>
            </p>
          </div>
        </div>
      </>
    )
  }
  
  export default privacypolicy